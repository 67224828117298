/** @format */

import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "util/asyncComponent";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <Route
        path={`${match.url}sub-admin`}
        component={asyncComponent(() => import("./SubAdmin"))}
      />
      <Route
        path={`${match.url}iam`}
        component={asyncComponent(() => import("./IAM"))}
      />
      <Route
        path={`${match.url}users`}
        component={asyncComponent(() => import("./Users"))}
      />
      <Route
        path={`${match.url}kyc`}
        component={asyncComponent(() => import("./Kyc"))}
      />
      <Route
        path={`${match.url}coins`}
        component={asyncComponent(() => import("./Coins"))}
      />
      <Route
        path={`${match.url}countries`}
        component={asyncComponent(() => import("./Coins/coinCountry.js"))}
      />
      <Route
        path={`${match.url}coins-countries-add`}
        component={asyncComponent(() => import("./Coins/addCountry.js"))}
      />

      <Route
        path={`${match.url}transactions`}
        component={asyncComponent(() => import("./Transactions"))}
      />

      <Route
        path={`${match.url}translations`}
        component={asyncComponent(() => import("./Translations/index.js"))}
      />

      <Route
        path={`${match.url}banks`}
        component={asyncComponent(() => import("./Banks"))}
      />
      <Route
        path={`${match.url}change-password`}
        component={asyncComponent(() => import("./SubAdmin/changePassword.js"))}
      />
      <Route
        path={`${match.url}activities`}
        component={asyncComponent(() => import("./Activities/index.js"))}
      />
      <Route
        path={`${match.url}whitelist`}
        component={asyncComponent(() => import("./Whitelist/index.js"))}
      />
      <Route
        path={`${match.url}requests`}
        component={asyncComponent(() => import("./AdminRequests/index.js"))}
      />
      {/*<Route
        path={`${match.url}sub-accounts`}
        component={asyncComponent(() => import("./SubAccounts/index.js"))}
      />*/}
      <Route
        path={`${match.url}sub-account-assets`}
        component={asyncComponent(() => import("./SubAccounts/assets.js"))}
      />
      <Route
        path={`${match.url}redis`}
        component={asyncComponent(() => import("./Redis/index.js"))}
      />
      <Route
        path={`${match.url}campaign`}
        component={asyncComponent(() => import("./Campaign/index.js"))}
      />
      <Route
        path={`${match.url}crypto-bundles`}
        component={asyncComponent(() => import("./Bundles"))}
      />
      <Route
        path={`${match.url}country`}
        component={asyncComponent(() => import("./Bundles/coinCountry.js"))}
      />
      <Route
        path={`${match.url}crypto-bundles-countries-add`}
        component={asyncComponent(() => import("./Bundles/addCountry.js"))}
      />

      <Route
        path={`${match.url}country-feature-control`}
        component={asyncComponent(() => import("./RemittanceFeature/index.js"))}
      />
      <Route
        path={`${match.url}announcement`}
        component={asyncComponent(() => import("./Announcement/index.js"))}
      />
      <Route
        path={`${match.url}limit-orders`}
        component={asyncComponent(() => import("./LimitOrders/index.js"))}
      />
      <Route
        path={`${match.url}leads`}
        component={asyncComponent(() => import("./Leads/index.js"))}
      />
      <Route
        path={`${match.url}maintenance`}
        component={asyncComponent(() => import("./Maintenance/index.js"))}
      />
      <Route
        path={`${match.url}trades`}
        component={asyncComponent(() => import("./TradeStatistics/index.js"))}
      />
      <Route
        path={`${match.url}reimbursements`}
        component={asyncComponent(() => import("./Transactions/Fiat/Refund"))}
      />
      <Route
        path={`${match.url}referrals`}
        component={asyncComponent(() => import("./Referrals"))}
      />
      <Route
        path={`${match.url}referrals-new`}
        component={asyncComponent(() => import("./Referrals/New"))}
      />
      <Route
        path={`${match.url}referee-reward`}
        component={asyncComponent(() => import("./RefereeReward"))}
      />
      <Route
        path={`${match.url}disputes`}
        component={asyncComponent(() => import("./Disputes"))}
      />
      <Route
        path={`${match.url}comply-advantages`}
        component={asyncComponent(() => import("./ComplyAdvantages"))}
      />
      <Route
        path={`${match.url}donations`}
        component={asyncComponent(() => import("./Donations"))}
      />
      <Route
        path={`${match.url}vouchers`}
        component={asyncComponent(() => import("./Vouchers"))}
      />
      <Route
        path={`${match.url}rewards`}
        component={asyncComponent(() => import("./Rewards"))}
      />
      <Route
        path={`${match.url}unauthorized`}
        component={asyncComponent(() => import("./unauthorized"))}
      />
      <Route
        path={`${match.url}banners`}
        component={asyncComponent(() => import("./AppBanner/index"))}
      />
      {/* <Route path={`${match.url}edit-app-banner`} component={asyncComponent(() => import("./AppBanner/EditBanner"))} />
      <Route path={`${match.url}add-app-banner`} component={asyncComponent(() => import("./AppBanner/AddBanner"))} /> */}
      <Route
        path={`${match.url}courses`}
        component={asyncComponent(() => import("./Courses"))}
      />
      <Route
        path={`${match.url}posts`}
        component={asyncComponent(() => import("./Posts"))}
      />
      <Route
        path={`${match.url}comments`}
        component={asyncComponent(() => import("./Comments"))}
      />
      <Route
        path={`${match.url}social-announcement`}
        component={asyncComponent(() => import("./Announcement/index.js"))}
      />
      <Route
        path={`${match.url}phonenumbers`}
        component={asyncComponent(() => import("./PhoneNumbers/index.js"))}
      />
      <Route
        path={`${match.url}vendors`}
        component={asyncComponent(() => import("./Vendors/index.js"))}
      />
      <Route
        path={`${match.url}otp`}
        component={asyncComponent(() => import("./OPT/index.js"))}
      />
      <Route
        path={`${match.url}staking-stats`}
        component={asyncComponent(() => import("./Staking/index.js"))}
      />
      <Route
        path={`${match.url}providers`}
        component={asyncComponent(() => import("./Providers/index.js"))}
      />
      <Route
        path={`${match.url}protocols`}
        component={asyncComponent(() => import("./Protocols"))}
      />
      <Route
        path={`${match.url}protocols/details`}
        component={asyncComponent(() => import("./Protocols/details"))}
      />
      <Route
        path={`${match.url}email-templates`}
        component={asyncComponent(() => import("./EmailTemplates/index.js"))}
      />
      <Route
        path={`${match.url}create-template`}
        component={asyncComponent(() =>
          import("./EmailTemplates/CreateTemplate.js")
        )}
      />
      {/* Liquidity Monitoring */}
      <Route
        path={`${match.url}liquidity-monitoring`}
        component={asyncComponent(() =>
          import("./LiquidityMonitoring/index.js")
        )}
      />
      {/* Savers */}
      <Route
        path={`${match.url}savers`}
        component={asyncComponent(() => import("./Savers/index.js"))}
      />
      <Route
        path={`${match.url}partners`}
        component={asyncComponent(() => import("./Partners/index.js"))}
      />
      <Route
        path={`${match.url}P2PMerchant`}
        component={asyncComponent(() => import("./P2PMerchant/index.js"))}
      />
      <Route
        path={`${match.url}P2PMerchantAds`}
        component={asyncComponent(() => import("./P2PMerchantAds/index.js"))}
      />
      <Route
        path={`${match.url}P2PTransactions`}
        component={asyncComponent(() => import("./P2PTransactions/index.js"))}
      />
      <Route
        path={`${match.url}P2PSettings`}
        component={asyncComponent(() => import("./P2PSettings/index.js"))}
      />
    </Switch>
    <Route
      path={`${match.url}emails`}
      component={asyncComponent(() => import("./PhoneNumbers/email.js"))}
    />
    <Route
      path={`${match.url}fees`}
      component={asyncComponent(() => import("./Fees/index.js"))}
    />
    <Route
      path={`${match.url}remittance`}
      component={asyncComponent(() => import("./Remittance/index.js"))}
    />
    <Route
      path={`${match.url}lean-approval`}
      component={asyncComponent(() => import("./LeanApproval/index.js"))}
    />
    <Route
      path={`${match.url}payment-method`}
      component={asyncComponent(() => import("./PaymentMethod"))}
    />
  </div>
);

export default App;
