/** @format */

import axiosMain from "axios";
import store from "../MyStore";
import {
  // INIT_URL,
  SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
  // USER_STATISTICS_DETAILS,
} from "../constants/ActionTypes";

const BASE_URL = process.env.REACT_APP_API_URL;
const BASE_INST_URL = process.env.REACT_APP_INST_API_URL;
const LOGIN = "/admin/login";

const GETUSER = "/admin/user/getUser/{userId}";
const USER_CRYPTO_BAL = "/admin/user/getUserCryptoBalance/{userId}";
const USER_CRYPTO_FIAT = "/admin/user/getUserCryptoAndFiatInfo/{userId}";
const CASH_BAL = "/admin/user/getUserTotalCash/{userId}";
const TRADE_VOL = "/admin/user/getUserTradeVolume/{userId}";
const COMPLY_ADVANTAGE_LIST = "/admin/user/listComplyAdvantage/{userId}";

const ADD_COIN = "/admin/coins/add";
const ACTIVATE_COIN = "/admin/coins/activate";
const DELETE_COIN = "/admin/coins/deActivate";
const COIN_INFO = "/admin/user/coins/info/{id}";
const BANK_STATEMENT_INFO = "/admin/banks/{id}";
const PROCESS_TRANSACTION = "/admin/processTransaction";
const APPROVE_DEPOSIT = "/admin/approveDeposit";
const APPROVE_WITHDRAW_STEP1 = "/admin/submitwithdrawForApproval";
const APPROVE_WITHDRAW_STEP2 = "/admin/approveWithdrawal";
const REJECT_WITHDRAW = "/admin/rejectWithdrawal/{tranId}";
const REJECT_DEPOSIT = "/admin/rejectDeposit/{tranId}";
const PROCESS_BANK = "/admin/bank/processBank";
const UPDATE_USER_BANK_INFO = "/admin/bank/update";
const CREATE_BANK = "/admin/bank/create";

const PROCESS_KYC = "/admin/user/processKyc";
const UPDATE_LEGAL_NAME = "/admin/user/updateLegalName/{id}";
const UPDATE_CUSTOMER_PHONE = "/admin/user/updatePhone";
const GET_CHANGE_NUM_HISTORY =
  "admin/user/phoneHistory/{limit}/{page}?type={type}&userId={userId}";

const KYC_LIST = "/admin/user/kycStage/all/50/{page}?key={key}&value={value}";

const CHANGE_PASSWORD = "/admin/changePassword";
const TRANSACTION_INFO = "/admin/deposits/{id}";
const ADD_TRANSACTION_AMOUNT = "/admin/fiat/addAmount";
const BANK = "/admin/userBanks";
const TRNX = "/admin/user/userTransactions";
const ORDERS = "/admin/user/userOrders";
const ADDRS = "/admin/user/userAddresses";
const ACTIVITIES = "/admin/listActivity/all/50/{page}?query={query}";
const USER_PORTFOLIO = "/admin/userPortfolio/{id}";
const CURRENCIES = "/admin/currencies";
const PROFITS = "/admin/profits/all/";
const PROFITS_COUNT = "/admin/profitsCount";

const WHITELIST = "/admin/listAllowedUsers/all/50/{page}?query={query}";
const ADD_WHITELIST_EMAILS = "/admin/addAllowedUsers";
const UPDATE_WHITELIST_EMAIL = "/admin/updateAllowedUsers";
const DELETE_WHITELIST_EMAIL = "/admin/deleteAllowedUsers/{id}";

const SUB_ACCOUNTS = "/admin/subAccounts";
const SUB_ACCOUNT_ASSETS = "/admin/subAccountAssets/{id}";
const MARKET = "/admin/market";
const DOC_UPLOAD = "/admin/kycDocuments";
const BANKSTATEMENT_UPLAOD = "/admin/bankStatement";

const ACITVE_USERS = "/admin/user/active/all/{pageSize}/{page}"; // Not in use
const INACTIVE_USERS = "/admin/user/inActive/all/{pageSize}/{page}"; // Not in use
const USERS_WITH_BALANCE =
  "/admin/listUsersWithBalance/{pageSize}/{page}?key={key}&value={value}";
// useless below
const ALL_BANKS = "/admin/banks/all/50/{page}?key={key}&value={value}";
const ALL_DEPOSIT = "/admin/deposits/all/50/{page}?query={query}";
const ALL_WITHDRAWALS = "/admin/withdrawals/all/50/{page}?query={query}";

const SELL_ORDERS = "/admin/listSellOrders/all/{pageSize}/{page}";
const BUY_ORDERS = "/admin/listBuyOrders/all/{pageSize}/{page}";
// useless above
const BUY_SELL_ORDERS = "/admin/listBuySellOrders/all/{limit}/{page}";
const SWAP_ORDERS = "/admin/listSwapOrders/all/{limit}/{page}";
const STOCK_ORDERS = "/admin/stocks/transactions";
const P2P_BUY = "/admin/p2p/orders?limit=#limit&page=#page";

const BUY_SELL_ORDERS_REPORT =
  "/admin/listBuySellOrdersReport/all/{limit}/{page}";
const P2P_BUY_REPORT = "/admin/p2p/ordersReport?limit=#limit&page=#page";

const TRADE_REPORT = "admin/user/tradeReport";

const SENT_TXN = "/admin/sentTransactions/all/{limit}/{page}";
const RECIEVE_TXN = "/admin/receiveTransactions/all/{pageSize}/{page}";
const ALL_USERS = "/admin/user/all/{pageSize}/{page}?key={key}&value={value}";

const REPORT = "/admin/listRangeReports/all";
const PROFIT_REPORT = "/admin/dashboard/getProfitRange";
const PARTIAL_KYC_REJECTION = "/admin/user/kyc/partialRejection";

const STATS_USER = "/admin/dashboard/usersStats";
const STATS_BANK_FIAT = "/admin/dashboard/bankFiatStats";
const STATS_PROFIT = "/admin/dashboard/ProfitStats";
const STATS_MASTER_USDT = "/admin/dashboard/masterUsdtStats";
const STATS_MASTER_BNB = "/admin/dashboard/listMasterBnbStats";
const STATS_CRYPTO_BALANCE = "/admin/dashboard/cryptoUsersBalanceStats";
const STATS_CRYPTO_TXN = "/admin/dashboard/cryptoTransactionStats";
const STATS_BANK_BALANCE = "/admin/listGlobals";

const UPDATE_BANK_BALANCE = "/admin/updateGlobals";
const ADD_BANK_ACCOUNT = "/admin/addGlobals";

const GAUTH_QR_CODE = "/admin/otp/qrcode";
const SETUP_GAUTH = "/admin/otp/gauth/setup";
const VERIFY_GAUTH_OTP = "/admin/verifyGauthOtp";
const VERIFY_EMAIL_OTP = "/admin/verifyEmailOtp";

const SEND_OTP = "/admin/sendOtp";
const GEN_USER_TOKEN = "/admin/generateUserToken";
const GRAPHS = "/admin/listGraphReport";

const DISABLE_GAUTH = "/admin/user/disableGauth";

const REDIS_DEL_ALL = "/redis/bulkDeleteKeys";
const REDIS_ALL_KEY = "/redis/allKeys";
const REDIS_GET_KEY = "redis/key/{key}";
const REDIS_CREATEUPDATE_KEY = "/redis/key";
const REDIS_DEL_KEY = "/redis/key/{key}";

const USER_ACCESS = "/admin/user/processUserAccess";
const REG_SUBACCOUNT = "/admin/user/subAccountReg/{userId}";

const LIST_ALL_CAMPAIGN = "/admin/campaign/all/{pageSize}/{page}";
const LIST_ALL_CRYPTO_BUNDLES = "/admin/bundles/get?{pageSize}&{page}";
const GET_BUNDLE_REPORT =
  "admin/bundles/transactions/download?from={fromDate}&to={toDate}&status={status}";

const CREATE_CAMPAIGN = "/admin/createCampaign";
const UPDATE_CAMPAIGN = "/admin/updateCampaign";
const CREATE_CRYPTO_BUNDLE = "/admin/bundles/create";
const UPDATE_CRYPTO_BUNDLE = "/admin/bundles/update/{id}";
const ACTIVATE_CAMPAIGN = "/admin/activateCampaign";

const LIST_ALL_ANNOUNCEMENT = "/admin/listAnnouncement/{pageSize}/{page}";
const CREATE_ANNOUNCEMENT = "/admin/createAnnouncement";

const LIST_MAINTENENCE = "/admin/announcements";
const CREATE_MAINTENENCE = "/admin/announcement";
const UPDATE_MAINTENENCE = "/admin/manageAnnouncement";

const LIMIT_ORDERS = "/admin/limitOrderAccounts";
const WITH_DOC = "/admin/withdrawal/document";

const EXCEL_REPORT = "/admin/dashboard/usersInfoDocument";
const ADMIN_ACTIVITIES_ON_USER = "/admin/user/approvals/{userId}";

const REFUND_USER = "/admin/userRefund";
const REFUND_LISTING = "/admin/userRefund/listing/{userId}";
const UNDO_REFUND = "/admin/userRefund/deActivate";

const PENDING_KYC =
  "/admin/user/listPendingKyc/{pageSize}/{page}?key={key}&value={value}";
const APPROVED_KYC =
  "/admin/user/listApprovedKyc/{pageSize}/{page}?key={key}&value={value}";
const REJECTED_KYC =
  "/admin/user/listRejectedKyc/{pageSize}/{page}?key={key}&value={value}";

const PENDING_BANK =
  "/admin/banks/listPending/{pageSize}/{page}?key={key}&value={value}";
const APPROVED_BANK =
  "/admin/banks/listApproved/{pageSize}/{page}?key={key}&value={value}";
const REJECTED_BANK =
  "/admin/banks/listRejected/{pageSize}/{page}?key={key}&value={value}";

const PENDING_DEPOSIT =
  "/admin/deposits/listPending/{pageSize}/{page}?key={key}&value={value}";
const APPROVED_DEPOSIT =
  "/admin/deposits/listApproved/{pageSize}/{page}?key={key}&value={value}";
const REJECTED_DEPOSIT =
  "/admin/deposits/listRejected/{pageSize}/{page}?key={key}&value={value}";
const EXPIRED_DEPOSIT =
  "/admin/deposits/listExpired/{pageSize}/{page}?key={key}&value={value}";

const PENDING_WITHDRAWAL =
  "/admin/withdrawals/listPendingWithdrawals/{pageSize}/{page}?key={key}&value={value}";
const PENDING_WITHDRAWAL_FOR_APPROVAL =
  "/admin/withdrawals/listPendingWithdrawalsForApproval/{pageSize}/{page}?key={key}&value={value}";
const APPROVED_WITHDRAWAL =
  "/admin/withdrawals/listApprovedWithdrawals/{pageSize}/{page}?key={key}&value={value}";
const REJECTED_WITHDRAWAL =
  "/admin/withdrawals/listRejectedWithdrawals/{pageSize}/{page}?key={key}&value={value}";

const LIST_LEADS = "/leads/listLeads";
const UPDATE_LEAD_STATUS = "/leads/updateLeadStatus";

const list_LEADS_NOTES = "/leads/listLeadsNotes";
const CREATE_LEADS_NOTES = "/leads/createleadsNotes";
const GET_LEAD_NOTE = "/leads/listAdminNote/{leadId}";

const USER_TRADE_STATS =
  "/admin/dashboard/userTradeStatistics/{pageSize}/{page}?query={query}";
const USER_REFUND_LIST =
  "/admin/userRefund/listAll/{pageSize}/{page}?query={query}";

const REF_LIST =
  "/admin/listAllReferenceCommision/{pageSize}/{page}?query={query}";
const REF_TOP_LIST = "/admin/listTopReferralUsers";
const REFFERALS = "/admin/user/referrals/{pageSize}/page";
const REF_REPORT = "/admin/dashboard/rangeReports/referralUser";

const DEPOSIT_LIMIT_USD = "/admin/user/totalDeposits/{userId}";
const USER_CARDS = "/admin/user/listCards/{userId}";
const USER_CARD_DEPOSITS = "/admin/user/listCardsDeposits/{userId}";
const LIST_CARD_DEPOSITS =
  "/admin/deposits/listCardDeposits/{limit}/{page}?key={key}&value={value}";
const TOTAL_CARD_DEPOSITS = "/admin/dashboard/totalCardDeposits";
const TOTAL_DEPOSITS = "/admin/user/totalCardTxn/{userId}";

const GET_DISPUTES =
  "/admin/p2p/orderDisputes?limit=#limit&page=#page&status=#status";
const GET_DISPUTE_DETAILS = "/admin/p2p/orderDisputes/:orderNumber";
const UPDATE_DISPUTE_STATUS = "/admin/p2p/orderDisputes/:orderNumber";
const UPDATE_ASSIGNEE =
  "/admin/p2p/orderDisputes/:orderNumber/changeAssigner/:targetAdminId";
const UPDATE_DISPUTE_RESOLUTION_NOTE =
  "/admin/p2p/orderDisputes/:orderNumber/notes";

const GET_ADMINS = "admin/list?isActive=true";

//Comply Advantage Paths
const COMPLY_ADVANTAGE = "/admin/complyAdvantage";
const ONGOING_MONITORING = "/admin/ongoingMonitoring";
const MONITORING = "/admin/complyAdvantage/monitoring";

//List All Allowed Countries
const LIST_COUNTRIES = "/admin/listCountries";
const LIST_COUNTRIES_FOR_KYC = "/admin/listCountries?status=1&feature=kyc";
const BLACKLISTED_COUNTRIES = "/admin/listCountries?status=0&feature=allowed";
const TOGGLE_COUNTRY_FEATURES = "/admin/toggleCountryFeatures/{id}";
const TOGGLE_COUNTRY = "/admin/toggleCountry/{id}";

//Update KYC
const UPDATE_KYC = "/admin/user/updateKycInfo";

// APP Banner

const GET_APP_BANNERS = "/admin/banners";
const POST_APP_BANNER = "/admin/banner";
const PATCH_APP_BANNER = "/admin/manageBanners";
//Courses
const GET_COURSES_LIST = "admin/courses?page={page}&limit={limit}";
const GET_COURSE = "/admin/course/{id}";
const UPDATE_COURSE = "/admin/course/{id}";
const ADD_COURSE = "/admin/course";
const UPDATE_COURSE_STATUS = "/admin/course/updateStatus/{id}";
const DELETE_COURSE_VIDEO = "/admin/course/{id}";

//chapter
const GET_CHAPTERS_LIST = "admin/chapters/{chapter}?page={page}&limit={limit}";
const ADD_CHAPTER = "/admin/chapter";
const UPDATE_CHAPTER = "/admin/chapter/{id}";
const SEARCH_COURSE = "/admin/courses?page={page}&limit={limit}&filter={query}";
const GET_CHAPTER = "/admin/chapter/{id}";

//posts
const GET_POSTS_LIST = "admin/posts/filter?page={page}&limit={limit}&{filter}";
const HIDE_UNHIDE_POST = "/admin/posts/toggleVisibility/{id}";
const listSocialComments =
  "admin/comments/filter?page={page}&limit={limit}&{filter}";
const hideShowlistSocialComments = "admin/comments/toggleVisibility/{id}";
const GET_POST_DATA = "admin/getPostDetails?post_id={id}";
// const GET_SOCIAL_ANNOUNCEMENT_LIST =
//   "admin/announcements/filter?page={page}&limit={limit}&{filter}";
const GET_SOCIAL_ANNOUNCEMENT_LIST = "admin/announcements/:limit/:page";
const HIDE_UNHIDE_Announcement = "admin/announcementPost/toggleVisibility/{id}";
const ADD_SOCIAL_ANNOUNCEMENT = "admin/post";
//Create Instance of Axios for File insertion with default settings

//chapter quiz

const GET_ALL_QUIZ = "admin/quizzes";
const ADD__QUIZ = "admin/quiz";
const PUT__QUIZ = "admin/quiz/{id}";
const GET_QUIZ_BY_ID = "admin/quiz/quizDetails/{id}";
const GET_QUIZ_DETAILS = "admin/quiz/{id}";
const DELETE__QUIZ = "admin/quiz/{id}";
const DELETE__QUESTION_QUIZ = "admin/quiz/deleteQuestion/{id}";
const ADD__QUIZ_QUESTION = "admin/quiz/question";
const EID_QUIZ_QUESTION = "admin/quiz/update/question";

//topics
const ADD_TOPIC = "admin/topic";
const GET_ALL_TOPICS = "admin/topics";
const UPDATE_TOPIC = "/admin/topic/{id}";
const DELETE_TOPIC = "admin/topic/{id}";

//testing phonenumbers
const GET_PHONE_NUMBERS_LIST = "admin/phone/getPhoneNumbers";
const ADD_PHONE_NUMBER = "admin/phone";

//testing emails
const GET_EMAILS_LIST = "admin/phone/getTestUser";
const UPDATE_EMAIL = "admin/phone/updateEmail?id={id}";

//vedors
const GET_VENDORS_LIST = "/smsProviders";
const UPDATE_VENDOR_SCORE = "/smsProviders/score/{id}";

const GET_OPD_METHODS = "admin/otpMethods";
const UPDATE_OPD_METHODS = "admin/otpMethods/{id}";

const GET_AUTH_LOGS = "admin/otpLogs?page={page}&limit={limit}&{filter}";

const GET_TRANSLATION = "admin/translationFiles/getFiles";

const CREATE_TRANSLATION = "/admin/translationFiles/updateFile";

const GET_LANGUAGES = "/admin/translationFiles/getLanguages";

const CREATE_LANGUAGES = "/admin/translationFiles/addLanguage";

const UPDATE_LANGUAGE_STATUS =
  "/admin/translationFiles/activateLanguage?status={statusValue}&id={langId}";
const GET_KYC_REJECTION_REASONS = "/admin/user/rejectionReasons/{userId}";

const GET_KYC_QUESTIONNAIRE = "admin/user/kycAnswers/{userId}";

// staking
const GET_STAKING_STATS = "admin/staking/stakes/stats";

// providers
const GET_PROVIDERS_LIST = "/admin/staking/providers/list";
const CREATE_PROVIDERS = "/admin/staking/providers/create";
const UPDATE_PROVIDERS = "/admin/staking/providers/update/{userId}";
const UPDATE_PROVIDERS_STATUS =
  "/admin/staking/providers/changeStatus/{userId}";
const INITIATE_REWARDS = "admin/cryptoTransferForMarketAndRefund";

const GET_SUBACCOUNT_TRANSFER_TXN =
  "admin/listSubAccountTransferTxn/:limit/:page";

// protocols
const GET_PROTOCOLS_LIST = "admin/staking/protocols/list";
const GET_PROTOCOLS_DETAIL = "/admin/staking/protocols/details/{id}";
const UPDATE_PROTOCOLS = "/admin/staking/protocols/update/{id}";
const UPDATE_DURATIONS = "/admin/staking/protocols/updateDuration/{id}";
const UPDATE_PROTOCOLS_STATUS =
  "/admin/staking/protocols/changeProtocolStatus/{id}";
const UPDATE_DURATIONS_STATUS =
  "/admin/staking/protocols/changeDurationStatus/{id}";
//Email Templats API
const GET_EMAIL_TEMPLATES =
  "/admin/translationFiles/getEmailTemplates?page={page}&pageSize={pageSize}";
const CREATE_EMAIL_TEMPLATE = "/admin/translationFiles/createEmailTemplates";
// Coin Country
const GET_COUNTRIES_BY_COIN = "/admin/coinCountries/countryByCoin/{symbol}";
const UPDATE_COUNTRY_STATUS = "/admin/coinCountries/activateOrIanctivate/{id}";
const ADD_COUNTRIES_BY_COIN = "/admin/coinCountries/assignAssetToCountry";
//Bundle Country
const GET_COUNTRIES_BY_BUNDLE =
  "/admin/bundleCountries/countryByBundle/{symbol}";
const UPDATE_BUNDLE_STATUS = "/admin/bundleCountries/activateOrDeactivate/{id}";
const ADD_COUNTRIES_BY_BUNDLE = "/admin/bundleCountries/assignBundleToCountry";
// POA Address
const PROCESS_POA = "/admin/processPOA";

//Maker Checker

const CHANGE_REQUESTS =
  "/admin/user/changeRequests/50/{page}?key={key}&value={value}&status={status}";

const GET_REVIEWERS = "/admin/user/reviewers";
const CHANGE_REQUEST = "/admin/user/changeRequest";
const REVIEW_REQUEST = "/admin/user/changeRequest/review/";
const GET_ANSWERS = "/admin/kyc/questions/";
const CHANGE_REQUEST_QUESTION = "/admin/user/kycAnswers/changeRequest";

// Fees
const ADD_TRANSACTION_FEES_AND_TAXES = "/admin/transactionFeesAndTaxes";
const GET_TRANSACTION_FEES_AND_TAXES_DATA = "admin/transactionFeesAndTaxes?";
const UPDATE_TRANSACTION_FEES_AND_TAXES =
  "/admin/transactionFeesAndTaxes/:{id}";
const GET_TRANSACTION_FEES_AND_TAXES_ENUM =
  "admin/transactionFeesAndTaxes/enum";

// User profile
const GET_USER_LIST_CRYPTO_DEPOSIT_TRANSACTIONS =
  "admin/listUserCrptoDepositTransactions/:id/:limit/:page";

// Crypto Txn - listAllCrptoDepositTransactions
const GET_LIST_ALL_CRYPTO_DEPOSIT_TRANSCATION_TXN =
  "admin/listAllCrptoDepositTransactions/:limit/:page";

// Liquidity Monitering
const GET_LIST_COIN_LIQUIDITY =
  "admin/dashboard/listCoinsLiquidity/:limit/:page";
const UPDATE_COIN_LIQUIDITY =
  "admin/dashboard/updateCoinsLiquidity/:liquidityId";
const GET_LIST_COIN_LIQUIDITY_HISTORIES =
  "admin/dashboard/listCoinsLiquidityHistories/:limit/:page ";

// Savers API List
const GET_LIST_SAVERS = "admin/yield/listProducts";
const UPDATE_RETURN_RATE = "admin/yield/updateReturnRate";
const UPDATE_DEPOSIT_LIMITS = "admin/yield/updateDepositLimit";

// Partners API List
const GET_LIST_PARTNERS = "admin/partners";
const ADD_PARTNERS = "admin/partners/";
const GET_PARTNERS_BY_ID = "admin/partners/:id";

// P2P Merchant API List
const GET_ALL_MERCHANTS_LIST = "admin/p2p/merchants";
const GET_MERCHANT_BY_ID = "admin/p2p/merchants/:id";
const CREATE_MERCHANT = "admin/p2p/merchants";
const UPDATE_MERCHANT = "admin/p2p/merchants/:id";
const ACTIVE_DEACTIVE_MERCHANT =
  "admin/p2p/merchants/:id/activateDeactivateMerchants";
const ONLINE_OFFLINE_MERCHANTS = "admin/p2p/merchants/:id/onlineOfflineMerchants";
// P2P Transactions API List
const GET_ALL_TRANSACTIONS_LIST =
  "admin/p2p/transactions?status=#status&page=#page&limit=#limit";
const GET_TRANSACTIONS_BY_ID = "admin/p2p/transactions/:id";
const APPROVE_RANSACTIONS = "admin/p2p/transactions/:id/approve";
const REJECT_RANSACTIONS = "admin/p2p/transactions/:id/reject";

const WITHDRAW_APPROVE_RANSACTIONS =
  "admin/p2p/transactions/:id/approveWithdrawal";
const WITHDRAW_REJECT_RANSACTIONS =
  "admin/p2p/transactions/:id/rejectWithdrawal";

// P2P Merchant Ads API List
const GET_ALL_MERCHANT_ADS_LIST = "admin/p2p/merchants/:merchantId/ads";
const GET_MERCHANTS_ADS_BY_ID = "admin/p2p/merchants/:merchantId/ads/:id";
const CREATE_MERCHANT_ADS = "admin/p2p/merchants/:merchantId/ads";
const UPDATE_MERCHANT_ADS = "admin/p2p/merchants/:merchantId/ads/:id";
const ACTIVE_DEACTIVE_MERCHANT_ADS =
  "admin/p2p/merchants/:merchantId/ads/:id/activateDeactivateMerchantAds";

// P2P Merchant Payment Method API List
const GET_MERCHANT_PAYMENT_METHOD_LIST =
  "admin/p2p/merchants/:merchantId/paymentMethods";
const GET_MERCHANT_PAYMENT_METHOD_BY_ID =
  "admin/p2p/merchants/:merchantId/paymentMethods/:id";
const CREATE_MERCHANT_PAYMENT_METHOD =
  "admin/p2p/merchants/:merchantId/paymentMethods";
const UPDATE_MERCHANT_PAYMENT_METHOD =
  "admin/p2p/merchants/:merchantId/paymentMethods/:id";
const ACTIVATE_DEACTIVATE_MERCHANT_PAYMENT_METHOD =
  "admin/p2p/merchants/:merchantId/paymentMethods/:id/activateDeactivateMerchantPaymentMethods";

// P2P Merchant API List
const GET_ALL_TRANSCATION_SETTINGS = "admin/p2p/transactionSettings";
const UPDATE_TRANSCATION_SETTINGS = "admin/p2p/transactionSettings";

// Referral
const REFERRAL_LIST_INVITEE = "admin/referral/listInvitee";
const REFERRAL_DOWNLOAD_INVITEE = "admin/referral/downloadInvitee";
const REFERRAL_DOWNLOAD_REFERRER_COMMISSION =
  "admin/referral/downloadReferrerCommission";
const REFERRAL_ACTION_LOG = "admin/referral/actionLog?rewardId={rewardId}";
const REFERRAL_LEADERBOARD = "admin/referral/leaderboard";
const REFERRAL_REWARD_RECORD =
  "admin/referral/refereeRewardRecord?pageNo={page}&pageSize={pageSize}&rewardStatus={rewardStatus}&startAt={startAt}&endAt={endAt}";
const REFERRAL_REFERRER_COMMISSION =
  "admin/referral/referrerCommission?pageNo={page}&pageSize={pageSize}";
const REFERRAL_REWARD_ACTION = "admin/referral/actionOnReward";
const REFERRAL_REWARD_DISBURSE = "admin/referral/rewardDisburse";

const REMITTANCE_GET_BUNDLES =
  "admin/remittance/getCddBeneficiaries?status={status}";
const REMITTANCE_UPDATE_CDD_BENEFICIARY =
  "admin/remittance/updateCddBeneficiary";

const LEAN_APPROVAL_LIST =
  "admin/lean/listTransaction?status={status}&limit={pageSize}&page={page}&{otherParamQuries}";

const PAYMENT_METHOD_LIST = "admin/paymentMethod/listPaymentMethods";
const CREATE_PAYMENT_METHOD = "admin/paymentMethod/createPaymentMethods";
const UPDATE_PAYMENT_METHOD = "admin/paymentMethod/updatePaymentMethods";
const CREATE_PAYMENT_LIMIT = "admin/paymentMethod/createPaymentMethodLimit";
const UPDATE_PAYMENT_LIMIT = "admin/paymentMethod/updatePaymentMethodsLimit";

const fileAxios = axiosMain.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    accept: "*/*",
    Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
  },
});

fileAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 403) {
      // window.location.href = "/unauthorized"
      return Promise.reject(error);
    }
    if (401 === error?.response?.status) {
      store.dispatch({ type: SIGNOUT_USER_SUCCESS, payload: null });
      localStorage.removeItem("adminToken");
      window.location.href = "/signin";
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

fileAxios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("adminToken")}`;
  return config;
});

const file_put = (url, data) => {
  return fileAxios.put(url, data, {
    headers: getToken(),
  });
};

//Create Instance of Axios with default settings
const axios = axiosMain.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
  },
});

const axiosInst = axiosMain.create({
  baseURL: BASE_INST_URL,
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
  },
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status == 403) {
      // window.location.href = "/unauthorized"
      return Promise.reject(error);
    }
    if (401 === error?.response?.status) {
      store.dispatch({ type: SIGNOUT_USER_SUCCESS, payload: null });
      localStorage.removeItem("adminToken");
      window.location.href = "/signin";
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

axios.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("adminToken")}`;
  return config;
});

const getToken = () => {
  let token = localStorage.getItem("adminToken");
  return {
    Authorization: "Bearer " + token,
  };
};

const _get = (url) => {
  return axios.get(url, {
    headers: getToken(),
  });
};

const _post = (url, data) => {
  return axios.post(url, data, {
    headers: getToken(),
  });
};

const _patch = (url, data) => {
  return axios.patch(url, data, {
    headers: getToken(),
  });
};

const _put = (url, data) => {
  return axios.put(url, data, {
    headers: getToken(),
  });
};

const _delete = (url) => {
  return axios.delete(url, {
    headers: getToken(),
  });
};

const doLogin = (body) => {
  return axios.post(LOGIN, body);
};

const createSubAdmin = (data) => {
  let url = "/admin/subAdmin/add";
  return _post(url, data);
};

const changePassword = (data) => {
  return _post(CHANGE_PASSWORD, data);
};

const resendPassSubAdmin = (data) => {
  let url = "/admin/subAdmin/resend";
  return _post(url, data);
};

const deActivateSubAdmin = (data) => {
  let url = "/admin/subAdmin/deActivate";
  return _patch(url, data);
};

const activateSubAdmin = (data) => {
  let url = "/admin/subAdmin/activate";
  return _patch(url, data);
};

const addWhitelistEmails = (body) => {
  return _post(ADD_WHITELIST_EMAILS, body);
};

const addCoin = (data) => {
  return _post(ADD_COIN, data);
};

const docUpload = (body) => {
  return _post(DOC_UPLOAD, body);
};
const bankStatementUpload = (body) => {
  return _post(BANKSTATEMENT_UPLAOD, body);
};
const report = (body) => {
  return _post(REPORT, body);
};
const profitReport = (body) => {
  return _post(PROFIT_REPORT, body);
};
const graphs = (body) => {
  return _post(GRAPHS, body);
};
const addBankAccount = (body) => {
  return _post(ADD_BANK_ACCOUNT, body);
};
const verifyGauthOtp = (body) => {
  return _post(VERIFY_GAUTH_OTP, body);
};
const verifyEmailOtp = (body) => {
  return _post(VERIFY_EMAIL_OTP, body);
};
const genUserToken = (body) => {
  return _post(GEN_USER_TOKEN, body);
};
const createUpdateRedisKey = (body) => {
  return _post(REDIS_CREATEUPDATE_KEY, body);
};
const createCryptoBundle = (body) => {
  return _post(CREATE_CRYPTO_BUNDLE, body);
};
const updateCryptoBundle = ({ id, ...body }) => {
  return _put(UPDATE_CRYPTO_BUNDLE.replace("{id}", id), body);
  return _patch(UPDATE_CRYPTO_BUNDLE.replace("{id}", id), body);
};
const toggleCountryFeatures = ({ id, ...body }) => {
  return _patch(TOGGLE_COUNTRY_FEATURES.replace("{id}", id), body);
};
const toggleCountry = ({ id, ...body }) => {
  return _patch(TOGGLE_COUNTRY.replace("{id}", id), body);
};
const createCampaign = (body) => {
  return _post(CREATE_CAMPAIGN, body);
};
const updateCampaign = (body) => {
  return _patch(UPDATE_CAMPAIGN, body);
};
const activateCampaign = (body) => {
  return _post(ACTIVATE_CAMPAIGN, body);
};
const createAnnouncement = (body) => {
  return _post(CREATE_ANNOUNCEMENT, body);
};
const createBank = (body) => {
  return _post(CREATE_BANK, body);
};
const genWithdrawalDoc = (body) => {
  return _post(WITH_DOC, body);
};
const refundUser = (body) => {
  return _post(REFUND_USER, body);
};
const undoRefund = (body) => {
  return _post(UNDO_REFUND, body);
};
const excelReport = (body) => {
  return _post(EXCEL_REPORT, body);
};
const updateUserBankInfo = (data) => {
  return _post(UPDATE_USER_BANK_INFO, data);
};
const totalCardDeposits = (data) => {
  return _post(TOTAL_CARD_DEPOSITS, data);
};

// Following are useless
const bank = (body) => {
  return _post(BANK, body);
};
const trnx = (body) => {
  return _post(TRNX, body);
};
const orders = (body) => {
  return _post(ORDERS, body);
};
const addrs = (body) => {
  return _post(ADDRS, body);
};
const setupGauth = (body) => {
  return _post(SETUP_GAUTH, body);
};
// Above are useless

const activateCoin = (data) => {
  return _patch(ACTIVATE_COIN, data);
};

const deActivateCoin = (data) => {
  return _patch(DELETE_COIN, data);
};

const processTransaction = (data) => {
  return _patch(PROCESS_TRANSACTION, data);
};

const approveDeposit = (data) => {
  return _patch(APPROVE_DEPOSIT, data);
};

const approveWithdrawalStep1 = (data) => {
  return _patch(APPROVE_WITHDRAW_STEP1, data);
};

const approveWithdrawalStep2 = (data) => {
  return _patch(APPROVE_WITHDRAW_STEP2, data);
};

const rejectWithdrawal = (tranId) => {
  return _patch(REJECT_WITHDRAW.replace("{tranId}", tranId));
};

const rejectDeposit = (tranId) => {
  return _patch(REJECT_DEPOSIT.replace("{tranId}", tranId));
};

const processBank = (data) => {
  return _patch(PROCESS_BANK, data);
};

const processKyc = (data) => {
  return _patch(PROCESS_KYC, data);
};

const partialRejection = (body) => {
  return _patch(PARTIAL_KYC_REJECTION, body);
};

const AddAmount = (data) => {
  return _patch(ADD_TRANSACTION_AMOUNT, data);
};

const updateLegalName = (data) => {
  return _put(UPDATE_LEGAL_NAME.replace("{id}", data.id), {
    legalName: data.legalName,
  });
};

const updateCustomerPhone = (data) => {
  return _patch(UPDATE_CUSTOMER_PHONE, data);
};

const getChangeNumHistoryRecord = ({ page, limit, userId, type }) => {
  return _get(
    GET_CHANGE_NUM_HISTORY.replace("{limit}", limit)
      .replace("{page}", page)
      .replace("{userId}", userId)
      .replace("{type}", type)
  );
};

const updateWhitelistEmail = (data) => {
  return _patch(UPDATE_WHITELIST_EMAIL, data);
};
const updateBankBalance = (data) => {
  return _patch(UPDATE_BANK_BALANCE, data);
};

const disableGauth = (body) => {
  return _patch(DISABLE_GAUTH, body);
};

const userAccess = (body) => {
  return _patch(USER_ACCESS, body);
};

// Delete Requests

const deleteWhitelistEmail = (body) => {
  return axios.delete(DELETE_WHITELIST_EMAIL.replace("{id}", body.id));
};
const deleteRedisKey = (body) => {
  return axios.delete(REDIS_DEL_KEY.replace("{key}", body.key));
};

// Get Requests

const limitOrders = () => {
  return _get(LIMIT_ORDERS);
};

const listCampaign = (pageSize, page) => {
  return _get(
    LIST_ALL_CAMPAIGN.replace("{pageSize}", pageSize).replace("{page}", page)
  );
};

const DeactivateCryptoBundles = (body) => {
  let url = "/admin/bundles/updateBundleStatus";
  return _patch(url, body);
};
const listCryptoBundles = (pageSize, page) => {
  return _get(
    LIST_ALL_CRYPTO_BUNDLES.replace("{pageSize}", `limit=${pageSize}`).replace(
      "{page}",
      `page=${page}`
    )
  );
};

const generateBundleReport = (fromDate, toDate, status, type, userID) => {
  let url;

  if (type != null) {
    url = GET_BUNDLE_REPORT + "&type={type}";
    return _get(
      url
        .replace("{fromDate}", fromDate)
        .replace("{toDate}", toDate)
        .replace("{status}", status)
        .replace("{type}", type)
    );
  }

  if (type != null && userID != null) {
    url = GET_BUNDLE_REPORT + "&type={type}" + "&userId={userID}";
    return _get(
      url
        .replace("{fromDate}", fromDate)
        .replace("{toDate}", toDate)
        .replace("{status}", status)
        .replace("{type}", type)
        .replace("{userID}", userID)
    );
  } else {
    return _get(
      GET_BUNDLE_REPORT.replace("{fromDate}", fromDate)
        .replace("{toDate}", toDate)
        .replace("{status}", status)
    );
  }
};

const listAnnouncement = (pageSize, page) => {
  return _get(
    LIST_ALL_ANNOUNCEMENT.replace("{pageSize}", pageSize).replace(
      "{page}",
      page
    )
  );
};

const listMaintainence = (pageSize = 20, page = 0) => {
  return _get(`${LIST_MAINTENENCE}/${pageSize}/${page}`);
};

const createMaintainence = (body) => {
  return _post(CREATE_MAINTENENCE, body);
};
const updateMaintainence = (body) => {
  return _patch(UPDATE_MAINTENENCE, body);
};

const getAllRedisKeys = () => {
  return _get(REDIS_ALL_KEY);
};

const getRedisKey = (body) => {
  return _get(REDIS_GET_KEY.replace("{key}", body.key));
};

const getAllSubAdmins = () => {
  let url = "/admin/subadmin/active/2000/0";
  return _get(url);
};

const inActiveSubAdmins = () => {
  let url = "/admin/subadmin/inActive/2000/0";
  return _get(url);
};

const getActiveUsers = (pageSize, page) => {
  return _get(
    ACITVE_USERS.replace("{pageSize}", pageSize).replace("{page}", page)
  );
};

const inActiveUsers = (pageSize, page) => {
  return _get(
    INACTIVE_USERS.replace("{pageSize}", pageSize).replace("{page}", page)
  );
};

const usersWithBalance = (pageSize, page, key, value) => {
  return _get(
    USERS_WITH_BALANCE.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

const getUser = (id) => {
  return _get(GETUSER.replace("{userId}", id));
};

const userCryptoBal = (id) => {
  return _get(USER_CRYPTO_BAL.replace("{userId}", id));
};

const userCryptoFiat = (id) => {
  return _get(USER_CRYPTO_FIAT.replace("{userId}", id));
};

const userCryptoTxns = (id) => {
  let url = "/admin/userCrptoTransactions";
  return _post(url, { userId: id });
};

const listUserCrptoDepositTransactions = (id, limit, page) => {
  return _get(
    GET_USER_LIST_CRYPTO_DEPOSIT_TRANSACTIONS.replace(":id", id)
      .replace(":limit", limit)
      .replace(":page", page)
  );
};

const listAllCrptoDepositTransactions = (limit, page) => {
  return _get(
    GET_LIST_ALL_CRYPTO_DEPOSIT_TRANSCATION_TXN.replace(
      ":limit",
      limit
    ).replace(":page", page)
  );
};

const cashBal = (id) => {
  return _get(CASH_BAL.replace("{userId}", id));
};

const tradeVol = (id) => {
  return _get(TRADE_VOL.replace("{userId}", id));
};

const complyAdvantageList = (id) => {
  return _get(COMPLY_ADVANTAGE_LIST.replace("{userId}", id));
};

const getAllCoins = () => {
  let url = "/admin/coins/all/2000/0";
  return _get(url);
};
const getAllBundleCoins = () => {
  let url = "/admin/bundle/coins";
  return _get(url);
};

const getCoinInfo = (id) => {
  return _get(COIN_INFO.replace("{id}", id));
};

const getAllDeposits = (page, query) => {
  return _get(ALL_DEPOSIT.replace("{page}", page).replace("{query}", query));
};

const getAllWithdrawals = (page, query) => {
  return _get(
    ALL_WITHDRAWALS.replace("{page}", page).replace("{query}", query)
  );
};

const getAllBanks = (page, key, value) => {
  return _get(
    ALL_BANKS.replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

const getBankStatement = (id) => {
  return _get(BANK_STATEMENT_INFO.replace("{id}", id));
};

const getSentTransactions = (limit, page, key, value) => {
  return _get(
    SENT_TXN.replace("{limit}", limit).replace("{page}", page) +
      `?key=${key}&value=${value}`
  );
};

const getReceiveTransactions = (page) => {
  // return _get(RECIEVE_TXN.replace("{page}", page));
  return _get("/admin/receiveTransactions/all/1000/0");
};

const listBuySellOrders = (limit, page, key, value) => {
  return _get(
    BUY_SELL_ORDERS.replace("{limit}", limit).replace("{page}", page) +
      `?key=${key}&value=${value}`
  );
};

const listBuySellOrdersReport = (limit, page, filter) => {
  return _get(
    BUY_SELL_ORDERS_REPORT.replace("{limit}", limit).replace("{page}", page) +
      "&" +
      filter
  );
};

const listSwapOrders = (limit, page, key, value) => {
  return _get(
    SWAP_ORDERS.replace("{limit}", limit).replace("{page}", page) +
      `?key=${key}&value=${value}`
  );
};

const listStockOrders = (limit, page, key, value) => {
  return _get(
    STOCK_ORDERS + `?page=${page}&limit=${limit}&key=${key}&value=${value}`
  );
};

const p2pBuy = (limit, page, filter) => {
  return _get(
    P2P_BUY.replace("#limit", limit).replace("#page", page) + "&" + filter
  );
};

const tradeReport = (body) => {
  return _post(TRADE_REPORT, body);
};

const p2pBuyReport = (limit, page, filter) => {
  return _get(
    P2P_BUY_REPORT.replace("#limit", limit).replace("#page", page) +
      "&" +
      filter
  );
};

const listSingleTransaction = (id) => {
  return _get(TRANSACTION_INFO.replace("{id}", id));
};

const getKycList = (page, key, value) => {
  return _get(
    KYC_LIST.replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

const getActivities = (page, query) => {
  return _get(ACTIVITIES.replace("{page}", page).replace("{query}", query));
};

const userPortfolio = (id) => {
  return _get(USER_PORTFOLIO.replace("{id}", id));
};

const currencies = () => {
  return _get(CURRENCIES);
};

const getProfits = () => {
  return _get(PROFITS + "2000/0");
};
const profitCount = () => {
  return _get(PROFITS_COUNT);
};

const getWhitelist = (page, query) => {
  return _get(WHITELIST.replace("{page}", page).replace("{query}", query));
};
const allUsers = (pageSize, page, key, value) => {
  return _get(
    ALL_USERS.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

const getSubAccounts = () => {
  return _get(SUB_ACCOUNTS);
};
const getSubAccoutnAssets = (body) => {
  return _get(SUB_ACCOUNT_ASSETS.replace("{id}", body.id));
};
const regSubAccount = (body) => {
  return _get(REG_SUBACCOUNT.replace("{userId}", body.userId));
};

const getMarket = () => {
  return _get(MARKET);
};

const usersStats = () => {
  return _get(STATS_USER);
};
const bankFiatStats = () => {
  return _get(STATS_BANK_FIAT);
};
const profitStats = () => {
  return _get(STATS_PROFIT);
};
const masterUsdtStats = () => {
  return _get(STATS_MASTER_USDT);
};
const masterBnbStats = () => {
  return _get(STATS_MASTER_BNB);
};
const cryptoUsersBalanceStats = () => {
  return _get(STATS_CRYPTO_BALANCE);
};
const cryptoTransactionStats = () => {
  return _get(STATS_CRYPTO_TXN);
};
const bankBalance = () => {
  return _get(STATS_BANK_BALANCE);
};
const qrCode = () => {
  return _get(GAUTH_QR_CODE);
};
const sendOtp = () => {
  return _get(SEND_OTP);
};
const adminActivitiesOnUser = (userId) => {
  return _get(ADMIN_ACTIVITIES_ON_USER.replace("{userId}", userId));
};
const refundListing = (userId) => {
  return _get(REFUND_LISTING.replace("{userId}", userId));
};

// KYC Reqs
const pendingKyc = (pageSize, page, key, value) => {
  return _get(
    PENDING_KYC.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const approvedKyc = (pageSize, page, key, value) => {
  return _get(
    APPROVED_KYC.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const rejectedKyc = (pageSize, page, key, value) => {
  return _get(
    REJECTED_KYC.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

// Bank Reqs
const pendingBanks = (pageSize, page, key, value) => {
  return _get(
    PENDING_BANK.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const approvedBanks = (pageSize, page, key, value) => {
  return _get(
    APPROVED_BANK.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const rejectedBanks = (pageSize, page, key, value) => {
  return _get(
    REJECTED_BANK.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

// Deposit Reqs
const pendingDeposit = (pageSize, page, key, value) => {
  return _get(
    PENDING_DEPOSIT.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const approvedDeposit = (pageSize, page, key, value) => {
  return _get(
    APPROVED_DEPOSIT.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const rejectedDeposit = (pageSize, page, key, value) => {
  return _get(
    REJECTED_DEPOSIT.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const expiredDeposit = (pageSize, page, key, value) => {
  return _get(
    EXPIRED_DEPOSIT.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

// Withdrawal Reqs
const pendingWithdrawal = (pageSize, page, key, value) => {
  return _get(
    PENDING_WITHDRAWAL.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const pendingWithdrawalForApproval = (pageSize, page, key, value) => {
  return _get(
    PENDING_WITHDRAWAL_FOR_APPROVAL.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const approvedWithdrawal = (pageSize, page, key, value) => {
  return _get(
    APPROVED_WITHDRAWAL.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};
const rejectedWithdrawal = (pageSize, page, key, value) => {
  return _get(
    REJECTED_WITHDRAWAL.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

//Fiat Transaction Report
const generateFiatReport = (data) => {
  let url = "/admin/user/transactionAnalysis";
  return _post(url, data);
};

//Profit ledger Transaction Report
const generateProfitLedgerReport = (fromDate, toDate) => {
  let url = `/admin/profitsToFile/${fromDate}/${toDate}`;
  return _post(url);
};

//CloudWatch Logs Report
const generateLogsReport = (data) => {
  let url = "/admin/listCloudWatchLogs ";
  return _post(url, data);
};

// LEADS
const listLeads = () => {
  return axiosInst.get(LIST_LEADS);
};
const updateLeadStatus = (body) => {
  return axiosInst.patch(UPDATE_LEAD_STATUS, body);
};
const createleadsNotes = (body) => {
  return axiosInst.post(CREATE_LEADS_NOTES, body);
};
const listLeadsNotes = () => {
  return axiosInst.get(list_LEADS_NOTES);
};
const getLeadNote = (leadId) => {
  return axiosInst.get(GET_LEAD_NOTE.replace("{leadId}", leadId));
};

//Admin Roles
const getAllGroups = () => {
  let url = "/admin/roles/groups";
  return _get(url);
};
const getAllPolicies = () => {
  let url = "/admin/roles/policies";
  return _get(url);
};
const addGroup = (data) => {
  let url = "/admin/roles/groups";
  return _post(url, data);
};
const addPolicy = (data) => {
  let url = "/admin/roles/policies";
  return _post(url, data);
};
const updateGroup = (data) => {
  let url = "/admin/roles/groups";
  return _put(url, data);
};
const updatePolicy = (data) => {
  let url = "/admin/roles/policies";
  return _put(url, data);
};
const deleteGroup = (id) => {
  let url = "/admin/roles/groups/" + id;
  return _delete(url);
};
const deletePolicy = (id) => {
  let url = "/admin/roles/policies/" + id;
  return _delete(url);
};
const getGroupPolicies = (id) => {
  let url = "/admin/roles/groupPolicyMap/" + id;
  return _get(url);
};
const attachGroupPolicies = (data) => {
  let url = "/admin/roles/groupPolicyMap";
  return _post(url, data);
};
const detachGroupPolicy = (id) => {
  let url = "/admin/roles/groupPolicyMap/" + id;
  return _delete(url);
};
const getUserAllPolicies = (userId, token) => {
  let url = "/admin/policies?id=" + userId;

  return axiosMain.get(url, {
    baseURL: BASE_URL,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  // return axios.get(url, {
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   },
  // });
};
const getUserPolicies = (id) => {
  let url = "/admin/roles/userPolicyMap/" + id;
  return _get(url);
};
const attachUserPolicies = (data) => {
  let url = "/admin/roles/userPolicyMap";
  return _post(url, data);
};
const detachUserPolicy = (id) => {
  let url = "/admin/roles/userPolicyMap/" + id;
  return _delete(url);
};
const assignGroupToUser = (data) => {
  let url = "/admin/roles/assignGroupToUser";
  return _post(url, data);
};
//--//

const userTradeStats = (pageSize, page, query) => {
  return _get(
    USER_TRADE_STATS.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{query}", query)
  );
};

const userRefund = (pageSize, page, query) => {
  return _get(
    USER_REFUND_LIST.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{query}", query)
  );
};

const refReport = (body) => {
  return _post(REF_REPORT, body);
};

const topReferrals = () => {
  return _get(REF_TOP_LIST);
};

const referrals = () => {
  return _get(REFFERALS);
};

const depositLimitUser = (userId) => {
  return _get(DEPOSIT_LIMIT_USD.replace("{userId}", userId));
};

const listReferral = (pageSize, page, query) => {
  return _get(
    REF_LIST.replace("{pageSize}", pageSize)
      .replace("{page}", page)
      .replace("{query}", query)
  );
};

const userCards = (userId) => {
  return _get(USER_CARDS.replace("{userId}", userId));
};

const userCardDeposits = (userId) => {
  return _get(USER_CARD_DEPOSITS.replace("{userId}", userId));
};

const listCardDeposits = (key, value, limit, page) => {
  return _get(
    LIST_CARD_DEPOSITS.replace("{limit}", limit)
      .replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
  );
};

const totalDepositUser = (userId) => {
  return _get(TOTAL_DEPOSITS.replace("{userId}", userId));
};

const getDisputes = (limit, page, status, filter) => {
  return _get(
    GET_DISPUTES.replace("#limit", limit)
      .replace("#page", page)
      .replace("#status", status) +
      "&" +
      filter
  );
};

const getDisputeDetails = (id) => {
  return _get(GET_DISPUTE_DETAILS.replace(":orderNumber", id));
};

const updateDisputestatus = (id, status, notes) => {
  return _put(
    UPDATE_DISPUTE_STATUS.replace(":orderNumber", id),
    notes
      ? {
          status,
          notes,
        }
      : { status }
  );
};

const getAdmins = () => {
  return _get(GET_ADMINS);
};

const updateAssignee = (orderNumber, targetAdminId) => {
  return _put(
    UPDATE_ASSIGNEE.replace(":orderNumber", orderNumber).replace(
      ":targetAdminId",
      targetAdminId
    )
  );
};

//Comply Advantage Reqs

const getComplyAdvantage = (page, limit, type, filterField, filterValue) => {
  return _get(
    `${COMPLY_ADVANTAGE}?page=${page}&limit=${limit}&type=${type}${
      filterField.length !== 0 && filterValue.length !== 0
        ? `&${filterField}=${filterValue}`
        : ""
    }`
  );
};

const getUserDetail = (recordId) => {
  return _get(`${COMPLY_ADVANTAGE}/${recordId}`);
};

const updateUserDetail = (data) => {
  return file_put(`${COMPLY_ADVANTAGE}`, data);
};

const updateDisputeResolutionNote = (orderNumber, notes) => {
  return _put(
    UPDATE_DISPUTE_RESOLUTION_NOTE.replace(":orderNumber", orderNumber),
    {
      notes,
    }
  );
};

const ongoingMonitoringList = (page, limit, type, filterField, filterValue) => {
  return _get(
    `${ONGOING_MONITORING}?page=${page}&limit=${limit}&type=${type}${
      filterField.length !== 0 && filterValue.length !== 0
        ? `&${filterField}=${filterValue}`
        : ""
    }`
  );
};

const updateOnGoingMonitoring = (data) => {
  return _patch(`${MONITORING}`, data);
};

//List Countries
const getCountries = () => {
  return _get(`${LIST_COUNTRIES}`);
};

const getCountriesForKYC = () => {
  return _get(`${LIST_COUNTRIES_FOR_KYC}`);
};

//Blacklisted Countries
const getBlacklistedCountries = () => {
  return _get(`${BLACKLISTED_COUNTRIES}`);
};

//Update KYC
const updateKYC = (body) => {
  return _patch(`${UPDATE_KYC}`, body);
};

//Education
const listCourses = ({ page, limit }) => {
  return _get(
    GET_COURSES_LIST.replace("{page}", page).replace("{limit}", limit)
  );
};

const deletCourse = (id) => {
  let url = "/admin/course/" + id;
  return _delete(url);
};

const getCourseById = (id) => {
  return _get(GET_COURSE.replace("{id}", id));
};

const updateCourse = (id, data) => {
  return _put(UPDATE_COURSE.replace("{id}", id), data);
};

const addCourse = (data) => {
  return _post(ADD_COURSE, data);
};

const updateCourseStatus = (id) => {
  return _put(UPDATE_COURSE_STATUS.replace("{id}", id));
};

const deleteCourseVideo = (id) => {
  return _patch(DELETE_COURSE_VIDEO.replace("{id}", id));
};

const addChapter = (data) => {
  return _post(ADD_CHAPTER, data);
};

const deleteChapter = (id) => {
  let url = "/admin/chapter/" + id;
  return _delete(url);
};

const updateChapter = (id, data) => {
  return _put(UPDATE_CHAPTER.replace("{id}", id), data);
};

const listChapterByCourse = (chapter, { page, limit }) => {
  return _get(
    GET_CHAPTERS_LIST.replace("{chapter}", chapter)
      .replace("{page}", page)
      .replace("{limit}", limit)
  );
};

const searchCourseQuery = (page, limit, value) => {
  return _get(
    SEARCH_COURSE.replace("{page}", page)
      .replace("{limit}", limit)
      .replace("{query}", value)
  );
};

const getChapterById = (id) => {
  return _get(GET_CHAPTER.replace("{id}", id));
};
const getAllQuizes = () => {
  return _get(GET_ALL_QUIZ);
};
const getQuizById = (id) => {
  return _get(GET_QUIZ_BY_ID.replace("{id}", id));
};
const getQuizDetails = (id) => {
  return _get(GET_QUIZ_DETAILS.replace("{id}", id));
};

const postQuizData = (data) => {
  return _post(ADD__QUIZ, data);
};
const updateQuizData = (data, quizId) => {
  return _put(PUT__QUIZ.replace("{id}", quizId), data);
};
const deleteQuiz = (id) => {
  return _delete(DELETE__QUIZ.replace("{id}", id));
};

const addQuizQuestion = (data) => {
  return _post(ADD__QUIZ_QUESTION.replace("{id}"), data);
};
const eidQuizQuestion = (data) => {
  return _put(EID_QUIZ_QUESTION, data);
};

const deleteQuizQuestion = (id) => {
  return _delete(DELETE__QUESTION_QUIZ.replace("{id}", id));
};

const addTopic = (data) => {
  return _post(ADD_TOPIC, data);
};

const getAllTopics = () => {
  return _get(GET_ALL_TOPICS);
};

const updateTopic = (id, data) => {
  return _put(UPDATE_TOPIC.replace("{id}", id), data);
};

const deleteTopic = (id) => {
  return _delete(DELETE_TOPIC.replace("{id}", id));
};

//Social Feed

const listPosts = ({ page, limit }, filter) => {
  return _get(
    GET_POSTS_LIST.replace("{page}", page)
      .replace("{limit}", limit)
      .replace("{filter}", filter)
  );
};

const updatePostStatus = (id) => {
  return _patch(HIDE_UNHIDE_POST.replace("{id}", id));
};

//comments
const listSocialFeedComments = ({ page, limit }, filter) => {
  return _get(
    listSocialComments
      .replace("{page}", page)
      .replace("{limit}", limit)
      .replace("{filter}", filter)
  );
};
const hideShowSocialFeedComments = (id) => {
  return _patch(hideShowlistSocialComments.replace("{id}", id));
};
const getPostData = (id) => {
  return _get(GET_POST_DATA.replace("{id}", id));
};

//social-announcements
const listSocialAnnouncements = (page, limit) => {
  return _get(
    GET_SOCIAL_ANNOUNCEMENT_LIST.replace(":page", page).replace(":limit", limit)
    // .replace("{filter}", filter)
  );
};

const updateAnnouncementStatus = (id) => {
  return _patch(HIDE_UNHIDE_Announcement.replace("{id}", id));
};

const createSocialAnnouncement = (data) => {
  return _post(ADD_SOCIAL_ANNOUNCEMENT, data);
};

//Donations
const getDonations = (pageSize, page) => {
  let url = `admin/donations/organization?limit=${pageSize}&page=${page}`;
  return _get(url);
};
const getDonationProjects = (pageSize, page) => {
  let url = `admin/donations/project?limit=${pageSize}&page=${page}`;
  return _get(url);
};
const getSingleDonationProject = (id) => {
  let url = `admin/donations/project/${id}`;
  return _get(url);
};
const getDonationTypes = (pageSize, page) => {
  let url = `admin/donationType/get?limit=${pageSize}&page=${page}`;
  return _get(url);
};
const getDonationCategories = (pageSize, page) => {
  let url = `admin/donationProjectCategory/get?limit=${pageSize}&page=${page}`;
  return _get(url);
};

const donationCategoryToggleStatus = (id, data) => {
  let url = `admin/donationProjectCategory/toggleStatus/${id}`;

  return _patch(url, data);
};
const donationTypesToggleStatus = (id, data) => {
  let url = `admin/donationType/toggleStatus/${id}`;

  return _patch(url, data);
};
const donationTypesDelete = (id) => {
  let url = `admin/donationType/delete/${id}`;

  return _delete(url);
};
const donationCategoryDelete = (id) => {
  let url = `admin/donationProjectCategory/delete/${id}`;

  return _delete(url);
};
const donationCategoryEdit = (id, data) => {
  let url = `admin/donationProjectCategory/update/${id}`;

  return _patch(url, data);
};
const donationCategoryCreate = (data) => {
  let url = `admin/donationProjectCategory/create`;

  return _post(url, data);
};
const donationTypeCreate = (data) => {
  let url = `admin/donationType/create`;

  return _post(url, data);
};
const donationTypeEdit = (id, data) => {
  let url = `admin/donationType/update/${id}`;

  return _patch(url, data);
};
const donationProjectToggleStatus = (id, data) => {
  let url = `admin/donations/project/${id}`;
  return _patch(url, data);
};

const createDonationOrg = (data) => {
  let url = "admin/donations/organization";

  return _post(url, data);
};

const getAppBanners = () => {
  return _get(GET_APP_BANNERS);
};

const postAppBanner = (data) => {
  return _post(POST_APP_BANNER, data);
};

const updateAppBanner = (data) => {
  return _patch(PATCH_APP_BANNER, data);
};
const createDonationProject = (data) => {
  let url = "admin/donations/project";
  return _post(url, data);
};
const donationProjectUpdateDetail = ({ id, data }) => {
  let url = `admin/donations/project/update/${id}`;
  return _patch(url, data);
};
//Vouchers
const createVoucher = (data) => {
  let url = "admin/vouchers/createVoucher";
  return _post(url, data);
};
const updateVoucher = (id, data) => {
  let url = `admin/vouchers/updateVoucher/${id}`;

  return _patch(url, data);
};
const getVouchers = ({ page, limit }) => {
  let url = `admin/vouchers/getVouchers?limit=${limit}&page=${page}`;
  return _get(url);
};
const getVoucherById = (id) => {
  let url = `admin/vouchers/getVoucher/${id}`;
  return _get(url);
};
const getAllowedVoucherListCountry = () => {
  let url = "admin/vouchers/listAllowedVoucherCurrencies";
  return _get(url);
};
const toggleVoucherStatus = (id, data) => {
  let url = `admin/vouchers/toggleVoucher/${id}`;
  return _patch(url, data);
};

const listTestingNumbers = ({ page, limit }) => {
  return _get(
    GET_PHONE_NUMBERS_LIST.replace("{page}", page).replace("{limit}", limit)
  );
};

const addPhoneNumber = (data) => {
  return _post(ADD_PHONE_NUMBER, data);
};

const deletPhoneNumber = (id) => {
  let url = "/admin/phone/" + id;
  return _delete(url);
};

const getVendorsList = () => {
  return _get(GET_VENDORS_LIST);
};
const updateVendorScore = (data) => {
  return _patch(UPDATE_VENDOR_SCORE.replace("{id}", data.id), {
    score: data.score,
  });
};

const getTestingEmails = () => {
  return _get(GET_EMAILS_LIST);
};
const getOPDMethods = () => {
  return _get(GET_OPD_METHODS);
};
const postOPDMethod = (data) => {
  return _post(GET_OPD_METHODS, data);
};

const updateOPDMethod = (id, data) => {
  return _put(UPDATE_OPD_METHODS.replace("{id}", id), data);
};

const updateTestingEmail = (data) => {
  return _put(UPDATE_EMAIL.replace("{id}", data.id), {
    email: data.email,
    updatedEmail: data.updatedemail,
  });
};

const listAuthLogs = ({ page, limit }, filter) => {
  return _get(
    GET_AUTH_LOGS.replace("{page}", page)
      .replace("{limit}", limit)
      .replace("{filter}", filter)
  );
};

const listTranslationFiles = () => {
  return _get(GET_TRANSLATION);
};

const createTranslationFile = (body) => {
  return _post(CREATE_TRANSLATION, body);
};

const getLanguages = () => {
  return _get(GET_LANGUAGES);
};

const createLanguage = (data) => {
  return _post(CREATE_LANGUAGES, data);
};

const updateLanguageStatus = (status, id) => {
  return _get(
    UPDATE_LANGUAGE_STATUS.replace("{statusValue}", status).replace(
      "{langId}",
      id
    )
  );
};
const getKYCRejectionReasons = (id) => {
  return _get(GET_KYC_REJECTION_REASONS.replace("{userId}", id));
};

const getKycQuestionnaire = (id) => {
  return _get(GET_KYC_QUESTIONNAIRE.replace("{userId}", id));
};

const getStakingStats = () => {
  return _get(GET_STAKING_STATS);
};

const getProvidersList = () => {
  return _get(GET_PROVIDERS_LIST);
};

const createProviders = (body) => {
  return _post(CREATE_PROVIDERS, body);
};

const updateProviders = (id, body) => {
  return _put(UPDATE_PROVIDERS.replace("{userId}", id), body);
};

const updateProviderStatus = (id, body) => {
  return _patch(UPDATE_PROVIDERS_STATUS.replace("{userId}", id), body);
};

const getProtocolsList = () => {
  return _get(GET_PROTOCOLS_LIST);
};

const getProtocolsDetails = (id) => {
  return _get(GET_PROTOCOLS_DETAIL.replace("{id}", id));
};

const updateProtocols = (id, body) => {
  return _put(UPDATE_PROTOCOLS.replace("{id}", id), body);
};

const updateDurations = (id, body) => {
  return _put(UPDATE_DURATIONS.replace("{id}", id), body);
};

const updateProtocolStatus = (id, body) => {
  return _patch(UPDATE_PROTOCOLS_STATUS.replace("{id}", id), body);
};

const updateDurationStatus = (id, body) => {
  return _patch(UPDATE_DURATIONS_STATUS.replace("{id}", id), body);
};

const getEmailTemplates = (page, pageSize) => {
  return _get(
    GET_EMAIL_TEMPLATES.replace("{page}", page).replace("{pageSize}", pageSize)
  );
};

const createEmailTemplates = (body) => {
  return _post(CREATE_EMAIL_TEMPLATE, body);
};

const initiateRewards = (data) => {
  return _post(INITIATE_REWARDS, data);
};

const getSubAccountTransferTxn = (limit, page) => {
  return _get(
    GET_SUBACCOUNT_TRANSFER_TXN.replace(":limit", limit).replace(":page", page)
  );
};

const getCoinCountries = (symbol) => {
  return _get(GET_COUNTRIES_BY_COIN.replace("{symbol}", symbol));
};

const updateCountryStatus = (id, body) => {
  return _patch(UPDATE_COUNTRY_STATUS.replace("{id}", id), body);
};

const addCountryByCoin = (body) => {
  return _post(ADD_COUNTRIES_BY_COIN, body);
};

const getBundleCountries = (symbol) => {
  return _get(GET_COUNTRIES_BY_BUNDLE.replace("{symbol}", symbol));
};

const updateBundleCountryStatus = (id, body) => {
  return _patch(UPDATE_BUNDLE_STATUS.replace("{id}", id), body);
};

const addCountryByBundle = (body) => {
  return _post(ADD_COUNTRIES_BY_BUNDLE, body);
};

const verifyPOA = (body) => {
  return _patch(PROCESS_POA, body);
};

//Maker Checker

const getChangeRequests = (page, key, value, status) => {
  return _get(
    CHANGE_REQUESTS.replace("{page}", page)
      .replace("{key}", key)
      .replace("{value}", value)
      .replace("{status}", status)
  );
};

const getReviewers = () => {
  return _get(`${GET_REVIEWERS}`);
};

const changeRequest = (data) => {
  return _post(CHANGE_REQUEST, data);
};

const reviewRequest = (id, data) => {
  return _post(`${REVIEW_REQUEST}${id}`, data);
};

const getAnswers = (id) => {
  return _get(`${GET_ANSWERS}${id}`);
};

const changeRequestQuestion = (data) => {
  return _post(CHANGE_REQUEST_QUESTION, data);
};

// Fees & Taxes

const addFeesAndTaxes = (body) => {
  return _post(ADD_TRANSACTION_FEES_AND_TAXES, body);
};

const getFeesAndTaxes = (body) => {
  return _get(
    `${GET_TRANSACTION_FEES_AND_TAXES_DATA}page=${body?.page}&limit=20&region=${body?.region}`
  );
};

const updateFeesAndTaxes = (id, body) => {
  return _patch(UPDATE_TRANSACTION_FEES_AND_TAXES.replace(":{id}", id), body);
};

const getFeesAndTaxesEnum = () => {
  return _get(GET_TRANSACTION_FEES_AND_TAXES_ENUM);
};

// Liquidity Monitoring Dashboard
const getListAllCoinOfLiquidity = (limit, page) => {
  return _get(
    GET_LIST_COIN_LIQUIDITY.replace(":limit", limit).replace(":page", page)
  );
};
const getListAllCoinOfLiquidityHistories = (limit, page) => {
  return _get(
    GET_LIST_COIN_LIQUIDITY_HISTORIES.replace(":limit", limit).replace(
      ":page",
      page
    )
  );
};

const updateLiquidityCoin = (id, body) => {
  return _patch(UPDATE_COIN_LIQUIDITY.replace(":liquidityId", id), body);
};

// Savers Monitoring Dashboard
const getListAllSavers = () => {
  return _get(GET_LIST_SAVERS);
};

const updateReturnRate = (data) => {
  return _put(UPDATE_RETURN_RATE, data);
};

const updateDepositLimit = (data) => {
  return _put(UPDATE_DEPOSIT_LIMITS, data);
};

// Partners Dashboard
const getListAllPartners = () => {
  return _get(GET_LIST_PARTNERS);
};

const addPartners = (data) => {
  return _post(ADD_PARTNERS, data);
};

const getPartnerById = (data) => {
  return _get(GET_PARTNERS_BY_ID.replace(":id", data));
};

// P2P Merchant Dashboard
const getListAllMerchants = () => {
  return _get(GET_ALL_MERCHANTS_LIST);
};

const onlineOfflineMerchants = (data) => {
  return _put(ONLINE_OFFLINE_MERCHANTS.replace(":id", data));
}

const getMerchantByID = (data) => {
  return _get(GET_MERCHANT_BY_ID.replace(":id", data));
};

const createMerchant = (data) => {
  return _post(CREATE_MERCHANT, data);
};

const updateMerchantById = (id, body) => {
  return _put(UPDATE_MERCHANT.replace(":id", id), body);
};
const activeDeactiveMerchant = (data) => {
  return _put(ACTIVE_DEACTIVE_MERCHANT.replace(":id", data));
};

// P2P Transcation Settings
const getTranactionSettings = () => {
  return _get(GET_ALL_TRANSCATION_SETTINGS);
};

const updateTranscationSettings = (body) => {
  return _put(UPDATE_TRANSCATION_SETTINGS, body);
};

// P2P Merchant Transactions Dashboard
const getListAllTransactions = (data) => {
  return _get(
    GET_ALL_TRANSACTIONS_LIST.replace("#status", data.status)
      .replace("#page", data?.page)
      .replace("#limit", data?.limit)
  );
};

const getTransactionById = (data) => {
  return _get(GET_TRANSACTIONS_BY_ID.replace(":id", data));
};

const approveTransaction = (data) => {
  return _put(APPROVE_RANSACTIONS.replace(":id", data));
};

const rejectTransaction = (id, body) => {
  return _put(REJECT_RANSACTIONS.replace(":id", id));
};

const withdrawApproveTransaction = (id, body) => {
  return _put(WITHDRAW_APPROVE_RANSACTIONS.replace(":id", id), body);
};

const withdrawRejectTransaction = (id, body) => {
  return _put(WITHDRAW_REJECT_RANSACTIONS.replace(":id", id), body);
};

// P2P Merchant ADS Dashboard
const getAllMerchantsAds = (data) => {
  return _get(GET_ALL_MERCHANT_ADS_LIST.replace(":merchantId", data));
};

const getMerchantAdsByID = (id, merchantId) => {
  return _get(
    GET_MERCHANTS_ADS_BY_ID.replace(":id", id).replace(
      ":merchantId",
      merchantId
    )
  );
};

const createMerchantAds = (merchantId, data) => {
  return _post(CREATE_MERCHANT_ADS.replace(":merchantId", merchantId), data);
};

const updateMerchantAdsById = (id, merchantId, body) => {
  return _put(
    UPDATE_MERCHANT_ADS.replace(":id", id).replace(":merchantId", merchantId),
    body
  );
};
const activeDeactiveMerchantAds = (merchantId, id) => {
  return _put(
    ACTIVE_DEACTIVE_MERCHANT_ADS.replace(":merchantId", merchantId).replace(
      ":id",
      id
    )
  );
};

// P2P Merchant Payment Method Dashboard
const getAllMerchantsPaymentMethods = (data) => {
  return _get(GET_MERCHANT_PAYMENT_METHOD_LIST.replace(":merchantId", data));
};

const getMerchantPaymentMethodById = (merchantId, id) => {
  return _get(
    GET_MERCHANT_PAYMENT_METHOD_BY_ID.replace(
      ":merchantId",
      merchantId
    ).replace(":id", id)
  );
};

const createMerchantPaymentMethod = (merchantId, data) => {
  return _post(
    CREATE_MERCHANT_PAYMENT_METHOD.replace(":merchantId", merchantId),
    data
  );
};

const updateMerchantPaymentMethod = (id, merchantId, body) => {
  return _put(
    UPDATE_MERCHANT_PAYMENT_METHOD.replace(":id", id).replace(
      ":merchantId",
      merchantId
    ),
    body
  );
};
const activeDeactiveMerchantPaymentMethod = (merchantId, id) => {
  return _put(
    ACTIVATE_DEACTIVATE_MERCHANT_PAYMENT_METHOD.replace(
      ":merchantId",
      merchantId
    ).replace(":id", id)
  );
};
/**
 * @param {number} page - page number - start from 1
 * @param {number} pageSize - number of record per page
 * @param {string} rewardStatus - Pending, Approved, Rejected, Flagged, DisbursedSuccess & DisbursedFailed
 * @param {number} startAt - time in UNIX format, eg: 1726019900
 * @param {number} endAt - time in UNIX format, eg: 1726019900
 */
const getRefereeRewardRecordList = (
  page,
  pageSize,
  rewardStatus,
  startAt = "",
  endAt = ""
) => {
  return _get(
    REFERRAL_REWARD_RECORD.replace("{page}", page)
      .replace("{pageSize}", pageSize)
      .replace("{rewardStatus}", rewardStatus)
      .replace("{startAt}", startAt)
      .replace("{endAt}", endAt)
  );
};

const getReferrerComissionList = (page, pageSize) => {
  return _get(
    REFERRAL_REFERRER_COMMISSION.replace("{page}", page).replace(
      "{pageSize}",
      pageSize
    )
  );
};

const getReferrerActionLogs = (rewardId, page, pageSize) => {
  return _get(REFERRAL_ACTION_LOG.replace("{rewardId}", rewardId));
};

const updateReferralRewardStatus = (body) => {
  return _post(REFERRAL_REWARD_ACTION, body);
};

const updateReferralRewardDisburse = (body) => {
  return _post(REFERRAL_REWARD_DISBURSE, body);
};

const getRemittanceBundles = (status) => {
  return _get(REMITTANCE_GET_BUNDLES.replace("{status}", status));
};

const updateRemittanceCDDBeneficiary = (body) => {
  return _post(REMITTANCE_UPDATE_CDD_BENEFICIARY, body);
};

/**
 * @param {number} page - page number - start from 1
 * @param {number} pageSize - number of record per page
 * @param {boolean} status - true (Approved/Rejected) or false (Pending)
 */
const getLeanApprovalRecordList = (
  page,
  pageSize,
  status,
  paramQuries = ""
) => {
  return _get(
    LEAN_APPROVAL_LIST.replace("{page}", page)
      .replace("{pageSize}", pageSize)
      .replace("{status}", status)
      .replace("{otherParamQuries}", paramQuries)
  );
};

/**
 * Retrieve all the configurations of Payment Method
 */
const getPaymentMethodConfig = () => {
  return _get(PAYMENT_METHOD_LIST);
};

/**
 * Create new Payment Method
 * @param {string} name - name of the Payment Method
 * @param {string} description - description of the Payment Method
 * @param {string} paymentType - refer to [PaymentMethodType](.././constants/Payment.js)
 * @param {string} iconUrl - URL of the display image of the Payment Method
 * @param {boolean} onRamp - option in payment method to restrict for buy stocks and crypto, Active = true, Off = false
 */
const createPaymentMethod = (
  name,
  description = "",
  paymentType,
  iconUrl = "",
  onRamp
) => {
  const body = {
    name,
    description,
    paymentType,
    iconUrl,
    onRamp,
  };

  return _post(CREATE_PAYMENT_METHOD, body);
};

/**
 * Update existing Payment Method information
 * @param {number} id - Payment Method ID
 * @param {string} name - name of the Payment Method
 * @param {string} description - description of the Payment Method
 * @param {string} paymentType - refer to [PaymentMethodType](.././constants/Payment.js)
 * @param {string} iconUrl - URL of the display image of the Payment Method
 * @param {boolean} onRamp - option in payment method to restrict for buy stocks and crypto, Active = true, Off = false
 */
const updatePaymentMethod = (
  id,
  name = null,
  description = null,
  paymentType = null,
  iconUrl = null,
  onRamp = null
) => {
  const body = {
    id,
    ...(name && { name }),
    ...(description && { description }),
    ...(paymentType && { paymentType }),
    ...(iconUrl && { iconUrl }),
    ...(typeof onRamp === "boolean" && { onRamp }),
  };

  return _put(UPDATE_PAYMENT_METHOD, body);
};

/**
 * Create new Payment Method Limit
 * @param {number} paymentMethodId - Payment Method ID
 * @param {number} fee - fee of Payment Method Limit
 * @param {number} feePercentage - fee (in %) of the Payment Method Limit 10% = 10
 * @param {number} minBuyLimitInUsd - Min amount of buy limit in USD
 * @param {number} minDeposit - Min Deposit amount
 * @param {number} maxDeposit - Max Deposit amount
 * @param {string} type - Type of Payment Method Limit, refer to [PaymentLimitType](.././constants/Payment.js)
 * @param {string} country - Country code (in 2 characters), refer to [CountryCode](.././constants/countryCode.js)
 * @param {string} processingTime - TEXT to describe the processing time
 */
const createPaymentMethodLimit = (
  paymentMethodId,
  fee,
  feePercentage,
  minBuyLimitInUsd,
  minDeposit,
  maxDeposit,
  type,
  country,
  processingTime = ""
) => {
  const body = {
    paymentMethodId,
    fee,
    feePercentage,
    minBuyLimitInUsd,
    minDeposit,
    maxDeposit,
    type,
    country,
    processingTime,
  };

  return _post(CREATE_PAYMENT_LIMIT, body);
};

/**
 * Update existing Payment Method Limit
 * @param {number} id - Payment Method Limit ID
 * @param {number} fee - fee of Payment Method Limit
 * @param {number} feePercentage - fee (in %) of the Payment Method Limit 10% = 10
 * @param {number} minBuyLimitInUsd - Min amount of buy limit in USD
 * @param {number} minDeposit - Min Deposit amount
 * @param {number} maxDeposit - Max Deposit amount
 * @param {string} processingTime - TEXT to describe the processing time
 * @param {boolean} isActive - status of Payment Method Limit, active = True, inactive = False
 */
const updatePaymentMethodLimit = (
  id,
  fee,
  feePercentage,
  minBuyLimitInUsd,
  minDeposit,
  maxDeposit,
  processingTime,
  isActive
) => {
  const body = {
    id,
    fee,
    feePercentage,
    minBuyLimitInUsd,
    minDeposit,
    maxDeposit,
    processingTime,
    isActive,
  };

  return _put(UPDATE_PAYMENT_LIMIT, body);
};

export default {
  donationTypeCreate,
  donationCategoryCreate,
  createVoucher,
  updateVoucher,
  getVouchers,
  getVoucherById,
  toggleVoucherStatus,
  getAllowedVoucherListCountry,
  donationTypeCreate,
  donationCategoryCreate,
  toggleCountryFeatures,
  toggleCountry,
  donationTypeEdit,
  donationCategoryEdit,
  donationTypesDelete,
  donationCategoryDelete,
  getDonations,
  donationTypesToggleStatus,
  createDonationProject,
  donationProjectUpdateDetail,
  createDonationOrg,
  donationProjectToggleStatus,
  donationCategoryToggleStatus,
  getDonationTypes,
  getDonationCategories,
  getDonationProjects,
  getSingleDonationProject,
  createCryptoBundle,
  doLogin,
  getAllSubAdmins,
  createSubAdmin,
  resendPassSubAdmin,
  getSentTransactions,
  getReceiveTransactions,
  deActivateSubAdmin,
  getActiveUsers,
  getUser,
  getAllCoins,
  addCoin,
  activateCoin,
  deActivateCoin,
  getCoinInfo,
  getAllDeposits,
  processTransaction,
  AddAmount,
  updateLegalName,
  updateCustomerPhone,
  getChangeNumHistoryRecord,
  getAllWithdrawals,
  approveDeposit,
  approveWithdrawalStep1,
  approveWithdrawalStep2,
  rejectWithdrawal,
  rejectDeposit,
  getAllBanks,
  getBankStatement,
  processBank,
  processKyc,
  changePassword,
  inActiveSubAdmins,
  activateSubAdmin,
  inActiveUsers,
  listSwapOrders,
  listStockOrders,
  listSingleTransaction,
  getKycList,
  bank,
  trnx,
  orders,
  addrs,
  getActivities,
  userPortfolio,
  currencies,
  getProfits,
  profitCount,
  usersWithBalance,
  getWhitelist,
  updateWhitelistEmail,
  deleteWhitelistEmail,
  addWhitelistEmails,
  getSubAccounts,
  getSubAccoutnAssets,
  getMarket,
  docUpload,
  bankStatementUpload,
  allUsers,
  report,
  partialRejection,
  listBuySellOrders,
  usersStats,
  bankFiatStats,
  profitStats,
  masterUsdtStats,
  masterBnbStats,
  cryptoUsersBalanceStats,
  cryptoTransactionStats,
  bankBalance,
  updateBankBalance,
  addBankAccount,
  qrCode,
  setupGauth,
  sendOtp,
  genUserToken,
  verifyGauthOtp,
  verifyEmailOtp,
  graphs,
  disableGauth,
  getAllRedisKeys,
  getRedisKey,
  createUpdateRedisKey,
  deleteRedisKey,
  userAccess,
  regSubAccount,
  listCampaign,
  listCryptoBundles,
  updateCryptoBundle,
  listAnnouncement,
  createCampaign,
  activateCampaign,
  createAnnouncement,
  limitOrders,
  createBank,
  updateCampaign,
  genWithdrawalDoc,
  refundUser,
  excelReport,
  adminActivitiesOnUser,
  DeactivateCryptoBundles,
  getAllBundleCoins,
  generateBundleReport,
  refundListing,
  undoRefund,
  profitReport,
  pendingKyc,
  approvedKyc,
  rejectedKyc,
  pendingBanks,
  approvedBanks,
  rejectedBanks,
  pendingDeposit,
  approvedDeposit,
  rejectedDeposit,
  expiredDeposit,
  pendingWithdrawal,
  pendingWithdrawalForApproval,
  approvedWithdrawal,
  rejectedWithdrawal,
  userCryptoBal,
  userCryptoFiat,
  cashBal,
  tradeVol,
  complyAdvantageList,
  listLeads,
  updateLeadStatus,
  createleadsNotes,
  listLeadsNotes,
  getLeadNote,
  getAllGroups,
  getAllPolicies,
  addGroup,
  addPolicy,
  updateGroup,
  updatePolicy,
  deleteGroup,
  deletePolicy,
  getGroupPolicies,
  attachGroupPolicies,
  detachGroupPolicy,
  getUserAllPolicies,
  getUserPolicies,
  attachUserPolicies,
  detachUserPolicy,
  assignGroupToUser,
  userTradeStats,
  userRefund,
  refReport,
  topReferrals,
  listReferral,
  generateFiatReport,
  generateProfitLedgerReport,
  generateLogsReport,
  userCryptoTxns,
  listUserCrptoDepositTransactions,
  listAllCrptoDepositTransactions,
  updateUserBankInfo,
  depositLimitUser,
  userCards,
  userCardDeposits,
  listCardDeposits,
  totalCardDeposits,
  totalDepositUser,
  p2pBuy,
  p2pBuyReport,
  listBuySellOrdersReport,
  getDisputes,
  getDisputeDetails,
  updateDisputestatus,
  getAdmins,
  updateAssignee,
  getComplyAdvantage,
  getUserDetail,
  updateUserDetail,
  updateDisputeResolutionNote,
  getCountries,
  getCountriesForKYC,
  getBlacklistedCountries,
  updateKYC,
  referrals,
  tradeReport,
  listMaintainence,
  createMaintainence,
  updateMaintainence,
  getAppBanners,
  postAppBanner,
  listCourses,
  getCourseById,
  deletCourse,
  updateCourse,
  addChapter,
  addCourse,
  updateCourseStatus,
  deleteChapter,
  listChapterByCourse,
  updateChapter,
  searchCourseQuery,
  getChapterById,
  getAllQuizes,
  getQuizById,
  postQuizData,
  updateQuizData,
  deleteQuiz,
  deleteQuizQuestion,
  getQuizDetails,
  addQuizQuestion,
  eidQuizQuestion,
  addTopic,
  getAllTopics,
  updateTopic,
  deleteTopic,
  listPosts,
  updatePostStatus,
  listSocialAnnouncements,
  updateAnnouncementStatus,
  createSocialAnnouncement,
  listMaintainence,
  createMaintainence,
  updateMaintainence,
  listSocialFeedComments,
  hideShowSocialFeedComments,
  getPostData,
  deleteCourseVideo,
  listTestingNumbers,
  addPhoneNumber,
  deletPhoneNumber,
  getVendorsList,
  updateVendorScore,
  getTestingEmails,
  updateTestingEmail,
  updateAppBanner,
  getOPDMethods,
  postOPDMethod,
  updateOPDMethod,
  listAuthLogs,
  listTranslationFiles,
  createTranslationFile,
  getLanguages,
  createLanguage,
  updateLanguageStatus,
  getKYCRejectionReasons,
  getKycQuestionnaire,
  getStakingStats,
  getProvidersList,
  createProviders,
  updateProviders,
  updateProviderStatus,
  getProtocolsList,
  getProtocolsDetails,
  updateProtocols,
  updateDurations,
  updateProtocolStatus,
  updateDurationStatus,
  getEmailTemplates,
  createEmailTemplates,
  initiateRewards,
  getSubAccountTransferTxn,
  ongoingMonitoringList,
  updateOnGoingMonitoring,
  getCoinCountries,
  updateCountryStatus,
  addCountryByCoin,
  getBundleCountries,
  updateBundleCountryStatus,
  addCountryByBundle,
  verifyPOA,
  getChangeRequests,
  getReviewers,
  changeRequest,
  reviewRequest,
  getAnswers,
  changeRequestQuestion,
  addFeesAndTaxes,
  getFeesAndTaxes,
  updateFeesAndTaxes,
  getFeesAndTaxesEnum,
  getListAllCoinOfLiquidity,
  getListAllCoinOfLiquidityHistories,
  updateLiquidityCoin,
  getListAllSavers,
  updateReturnRate,
  updateDepositLimit,
  getListAllPartners,
  addPartners,
  getPartnerById,
  getListAllMerchants,
  getMerchantByID,
  createMerchant,
  updateMerchantById,
  activeDeactiveMerchant,
  getListAllTransactions,
  getTransactionById,
  approveTransaction,
  rejectTransaction,
  withdrawApproveTransaction,
  withdrawRejectTransaction,
  onlineOfflineMerchants,
  // Get All Merchant AD
  getAllMerchantsAds,
  getMerchantAdsByID,
  createMerchantAds,
  updateMerchantAdsById,
  activeDeactiveMerchantAds,
  // Marchant Payment Method
  getAllMerchantsPaymentMethods,
  getMerchantPaymentMethodById,
  createMerchantPaymentMethod,
  updateMerchantPaymentMethod,
  activeDeactiveMerchantPaymentMethod,
  // P2P Transcation Settings
  getTranactionSettings,
  updateTranscationSettings,
  // Referral
  getRefereeRewardRecordList,
  getReferrerComissionList,
  updateReferralRewardStatus,
  getReferrerActionLogs,
  updateReferralRewardDisburse,
  // Remittance
  getRemittanceBundles,
  updateRemittanceCDDBeneficiary,
  // LEAN approval
  getLeanApprovalRecordList,
  // Payment Methods
  getPaymentMethodConfig,
  createPaymentMethod,
  updatePaymentMethod,
  createPaymentMethodLimit,
  updatePaymentMethodLimit,
};
